import React from 'react'
import { Anchor, Box, Image, ResponsiveContext, Text } from 'grommet'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import favicon from '../images/favicon.ico'
import Garrett from '../assets/garrett2.png'
import Garrett2 from '../assets/garrett3.png'

const ContactPage = () => {
  return (
    <Layout location={'/contact'}>
      <Helmet title="Contact | Carwash Music">
        <link rel="icon" href={favicon} />
      </Helmet>
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            align="center"
            direction={size !== 'small' ? 'row' : 'column'}
            flex="grow"
            gap="large"
            justify="center"
          >
            {size !== 'small' ? (
              <Box width="medium" alignSelf="center">
                <Image src={Garrett2} />
              </Box>
            ) : (
              undefined
            )}
            <Box
              align="center"
              pad={size === 'small' ? { top: 'medium' } : undefined}
            >
              <Text size="small">mgmt:</Text>
              <Anchor
                label={
                  <Text weight={900} size="small" color="#17355f">
                    taylor@tnd.media
                  </Text>
                }
                href="mailto:mgmt@postcardboymusic.com"
              />
            </Box>
            {size === 'small' ? (
              <Box width="80%" alignSelf="center">
                <Image src={Garrett2} />
              </Box>
            ) : (
              undefined
            )}
            <Box width={size !== 'small' ? 'medium' : '80%'}>
              <Image src={Garrett} />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  )
}

export default ContactPage
